.home-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding: 2rem;
}
.linkedinLink{
  background: #0077b5;
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.25rem;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;



}
.linkedinLink:hover{
  background: #005f91;
  color: white;
}
.min-height {
  min-height: 100vh;
}
.home-main {
  width:700px;
  display: flex;
  margin-top:60px;
  flex-direction: column; /* Reverse the order of items to place the image on the right */
  align-items:center; /* Align items to the top */
  justify-content: center; /* Justify content to the right */
  /* height: 60vh; */
}
.person__{
  display: flex;
}
.person__ .content{
  width:50%;
}
.developer-image {
  max-width: 50%;
  max-height: 50%; /* Set the maximum width and height of the image */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  align-self: flex-start; /* Align the image to the top */
  border-radius: 20px; /* Add rounded corners to the image */
}
.home-main .about-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
 background:none;
 margin-bottom: 100px;
}

.content {
  width: 50%; /* Adjust the width of the content */
  padding: 30px 40px;
  color: black;
}




@keyframes shake {
    0%{
      transform: rotate(0deg);

    }
    25%{
      transform: rotate(5deg);

    }
    50%{
      transform: rotate(360deg);

    }
    75%{
      transform: rotate(-5deg);

    }
    100%{
      transform: rotate(0deg);

    }

}


.home-main h1, .home-main h2 {
  margin: 0.5rem 0; 
     /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
}


.home-arrow {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .home-main{
    width:100%;
  
  }

  .home-main h1 {
    font-size: 1.75rem; 
  }

  .home-main h2 {
    font-size: 1.25rem;
  }
}
