@import "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap";
--root {
  --primary: #4e567e;
  --secondary: #f4f4f4;
  --tertiary: #f4f4f4;
  --quaternary: #f4f4f4;
  --quinary: #f4f4f4;
  --senary: #f4f4f4;
  --septenary: #f4f4f4;
  --octonary: #f4f4f4;
  --nonary: #f4f4f4;
  --denary: #f4f4f4;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  overflow-x: hidden;
}

h1 {
  margin: 0;
  font-size: 7rem;
  font-weight: 400;
  line-height: .8;
}

h2 {
  margin: 0;
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
}

p.large {
  font-size: 24px;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

.padding {
  padding: 20px;
}

.background {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.about-container {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
}

.large {
  font-size: 1.25rem;
}

.about-container hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.about-container ul {
  text-align: left;
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
  gap: 3rem;
  font-size: 1.25rem;
}

.about-container li {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .about-container {
    width: 75%;
  }
}

@media (min-width: 1024px) {
  .about-container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .about-container ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

.home-section {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
  position: relative;
}

.linkedinLink {
  color: #fff;
  background: #0077b5;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.25rem;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.linkedinLink:hover {
  color: #fff;
  background: #005f91;
}

.min-height {
  min-height: 100vh;
}

.home-main {
  width: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  display: flex;
}

.person__ {
  display: flex;
}

.person__ .content {
  width: 50%;
}

.developer-image {
  max-width: 50%;
  max-height: 50%;
  object-fit: cover;
  border-radius: 20px;
  align-self: flex-start;
}

.home-main .about-container {
  width: 100%;
  background: none;
  justify-content: space-between;
  margin-bottom: 100px;
  display: flex;
}

.content {
  width: 50%;
  color: #000;
  padding: 30px 40px;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(360deg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.home-main h1, .home-main h2 {
  margin: .5rem 0;
}

.home-arrow {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .home-main {
    width: 100%;
  }

  .home-main h1 {
    font-size: 1.75rem;
  }

  .home-main h2 {
    font-size: 1.25rem;
  }
}

.portfolio__container {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.portfolio__container_main {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  display: flex;
}

.project__container {
  width: 100%;
}

.box {
  border-bottom: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
}

@media (min-width: 768px) {
  .portfolio__container_main {
    flex-direction: column;
  }

  .project__container {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .portfolio__container_main {
    flex-direction: row;
    justify-content: space-between;
  }

  .box {
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .portfolio__container_image, .project__container {
    width: 70%;
  }
}

/*# sourceMappingURL=index.6d410aec.css.map */
